<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
html {
  width: 100%;
  height: 100%;
  background-color: rgba(47, 121, 216, 1);
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.el-loading-spinner .path {
  stroke: white !important;
}
.el-loading-spinner .el-loading-text {
  color: white !important;
}
.el-page-header,
.el-page-header__content {
  color: white !important;
}
.el-page-header__left {
  margin-right: 16px !important;
}
.el-page-header__left::after {
  right: -8px !important;
}
.el-button {
  border: none !important;
}
.el-dialog {
  border-radius: 15px !important;
}
.el-dialog__header {
  border-radius: 15px 15px 0 0 !important;
}
.el-dialog__body {
  border-radius: 0 0 15px 15px !important;
}
</style>
